
// const tacticsItem=()=>import('@/components/tacticsItem/index.vue')
import tacticsItem from '@/components/tacticsItem/index.vue'
import {defineComponent, ref, reactive} from 'vue'
export default defineComponent({
  data() {
    return {
      tacticsItems: reactive([]), // 策略列表
      active: 'user1',  // tab激活value
      tabs: [     // tabs内容
        {
          label: '第一期',
          value: 'user1'
        },
        {
          label: '第二期',
          value: 'user2'
        },
        {
          label: '第三期',
          value: 'user3'
        },
        {
          label: '第四期',
          value: 'user4'
        },
      ]
    }
  },
  components: {
    "tactics-item": tacticsItem
  },
  methods: {
    // 获取所有组合
    async getStrategys(){
      let res = await this.$api.getUserStrategys({
        path: this.active
      })
      this.tacticsItems = res.sort((a, b)=>{
        return b.strategy_detail.total_gain - a.strategy_detail.total_gain
      })
      // console.log(this.tacticsItems)
    },

    // 跳转详情
    goDetail(item){
      if (item.is_need_to_fetch && item.is_need_to_fetch=='N') return
      // 目前先跳转雪球
      const url = `https://xueqiu.com/P/${item.strategy_id}`
      window.open(url, '_blank')
    },

    // 切换
    tabChange(target){
      // console.log(target, target.paneName)
      this.getStrategys()
    }
  },
  mounted() {
    this.getStrategys()
  }
})
